import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const StyledBlog = styled.section`
  background-color: ${({ theme }) => theme.color.light};
  transition: all 0.3s ease;

  @media (min-width: 992px) {
    & h2 {
      font-size: 26px !important;
      margin-bottom: 0 !important;
    }
  }
`

const Blog: React.FC<BlogProps> = ({ fields }) => (
  <StyledBlog>
    <div className="container py-lg-5 pb-3">
      <div className="row justify-content-end">
        <div className="col-lg-8">
          <ParseContent content={fields.description || ''} />
        </div>
      </div>
    </div>
  </StyledBlog>
)

export default Blog
