import { useContext } from 'react'

import { ProfileContext } from 'templates/profile'

const useProfile = () => {
  const context = useContext(ProfileContext)

  return context
}

export default useProfile
