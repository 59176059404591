import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const Content = styled(ParseContent)`
  @media (min-width: 992px) {
    & h2 {
      font-size: 75px !important;
    }
  }
`

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const StyledText = styled.section`
  transition: all 0.3s ease;
`

const Text: React.FC<TextProps> = ({ fields }) => (
  <StyledText className={`bg-${fields.background}`}>
    <div className="container py-3">
      <Content content={fields.description || ''} />
    </div>
  </StyledText>
)

export default Text
