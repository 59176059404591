import React from 'react'

// Images
import arrow from 'img/arrow.svg'
import altarrow from 'img/altarrow.svg'
import CtaPhone from 'img/ctaphone.inline.svg'
import CtaMail from 'img/ctamail.inline.svg'
import CtaWhatsapp from 'img/whatsapp.inline.svg'
import Clock from 'img/clock.inline.svg'
import Pin from 'img/pin.inline.svg'
import Bars from 'img/bars.inline.svg'
import upload from 'img/upload.svg'

// Components
import ParseContent from 'components/shared/ParseContent'
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'
import Button from 'components/elements/Buttons/Button'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled, { css } from 'styled-components'
import useLayout from 'hooks/useLayout'
import useVacancy from 'hooks/useVacancy'
import Share from 'components/elements/Share'

interface VacancyProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
  location: any
}

const StyledVacancy = styled.section`
  background-color: ${({ theme }) => theme.color.light};
  transition: all 0.3s ease;
`

const Block = styled.div<{ darkmode: boolean }>`
  background-color: ${({ theme }) => theme.color.light};
  transition: all 0.3s ease;
  padding: 50px 100px 50px 100px;
  position: relative;
  margin-top: -150px;
  z-index: 5;

  ${({ darkmode }) =>
    darkmode
      ? css`
          filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.25));
        `
      : css`
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
        `}

  @media screen and (max-width: 1024px) {
    padding: 30px 50px 30px 50px;
  }

  @media screen and (max-width: 575px) {
    padding: 15px 15px;
  }
`

const ApplyContainer = styled.div`
  background: linear-gradient(260.72deg, #09154f 7.53%, #1402ac 117.72%);
  padding: 50px 0;

  @media screen and (max-width: 991px) {
    padding: 30px 0;
  }

  @media screen and (max-width: 575px) {
    padding: 15px 0 0 0;
  }
`

const CTA = styled.div`
  & h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-size: 20px;
    line-height: 35px;
    margin-bottom: 0;
  }

  & p {
    font-size: 16px;
    line-height: 25px;
  }

  & a {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-size: 16px;
    line-height: 25px;
    color: ${({ theme }) => theme.color.text.primaryLight};
    font-weight: ${({ theme }) => theme.font.weight.light};

    &:hover {
      text-decoration: underline;
    }
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  height: 144px;
  width: 144px;
  border: 3px solid ${({ theme }) => theme.color.secondary};
  border-radius: 20px;

  & img {
    border-radius: 20px;
  }
`

const IconWrapper = styled.div`
  width: 20px;
`

const USPS = styled.div<{ darkmode: boolean }>`
  padding-bottom: 50px;

  & h3 {
    font-size: 16px;
    color: ${({ theme }) => theme.color.dark};
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.light};
  }

  & button {
    min-width: 205px;
  }

  & svg {
    margin-right: 10px;

    & path {
      ${({ darkmode, theme }) =>
        darkmode
          ? css`
              fill: ${theme.color.secondary};
            `
          : css`
              fill: ${theme.color.primaryLight};
            `}
    }

    & rect {
      ${({ darkmode, theme }) =>
        darkmode
          ? css`
              stroke: ${theme.color.secondary};
            `
          : css`
              stroke: ${theme.color.primaryLight};
            `}
    }
  }

  @media screen and (min-width: 1025px) {
    margin: 0 -40px;
  }

  @media screen and (max-width: 991px) {
    padding-bottom: 20px;
  }
`

const ShareContainer = styled.div`
  position: absolute;
  left: -90px;
  top: 180px;

  @media screen and (max-width: 991px) {
    position: relative;
    display: flex;
    justify-content: flex-start;
    left: -10px;
    top: 0;
  }

  @media screen and (max-width: 575px) {
    left: 0;
    top: 0;
  }
`

const SvgWrapper = styled.div`
  @media screen and (max-width: 575px) {
    width: 30px;
    display: flex;
    justify-content: flex-start;
  }
`

const Vacancy: React.FC<VacancyProps> = ({ fields, location }) => {
  const layout = useLayout()
  const vacancy: any = useVacancy()

  return (
    <StyledVacancy>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <Block darkmode={layout.darkMode}>
              <ShareContainer>
                <Share title={vacancy.title} location={location} />
              </ShareContainer>
              <USPS
                darkmode={layout.darkMode}
                className="d-flex flex-wrap justify-content-between"
              >
                <div className="row w-100 pt-2">
                  <h3 className="col-sm-4 d-flex justify-content-center align-items-center">
                    <SvgWrapper>
                      <Clock />
                    </SvgWrapper>
                    {vacancy.preview.type}
                  </h3>
                  <h3 className="col-sm-4 d-flex justify-content-center align-items-center">
                    <SvgWrapper>
                      <Pin />
                    </SvgWrapper>
                    {vacancy.preview.location}
                  </h3>
                  <h3 className="col-sm-4 d-flex justify-content-center align-items-center">
                    <SvgWrapper>
                      <Bars />
                    </SvgWrapper>
                    {vacancy.preview.niveau}
                  </h3>
                </div>
                <div className="pt-3 d-flex w-100 justify-content-center">
                  <Button
                    arrow
                    className="filled secondary hover-secondary"
                    isCustom
                  >
                    <a href="#apply">
                      {layout.locale === 'nl_NL'
                        ? `Solliciteer direct`
                        : `Apply now`}
                    </a>
                  </Button>
                </div>
              </USPS>
              <ParseContent content={fields.description || ''} />
              <CTA className="d-flex flex-sm-nowrap flex-wrap pt-lg-5 pt-3">
                <div className="me-5 pb-sm-0 pb-3">
                  <StyledPlaatjie image={fields.ctaimage} alt="" />
                </div>
                <div>
                  <ParseContent content={fields.ctadescription || ''} />
                  <div className="pt-3">
                    <div className="d-flex align-items-center pb-1">
                      <IconWrapper className="d-flex justify-content-center me-3">
                        <CtaPhone />
                      </IconWrapper>
                      <a target="__blank" href={fields.ctaphone?.url || '/'}>
                        {fields.ctaphone?.title}
                      </a>
                    </div>
                    <div className="d-flex align-items-center">
                      <IconWrapper className="d-flex justify-content-center me-3">
                        <CtaMail />
                      </IconWrapper>
                      <a target="__blank" href={fields.ctaemail?.url || '/'}>
                        {fields.ctaemail?.title}
                      </a>
                    </div>
                    <div className="d-flex align-items-center">
                      <IconWrapper className="d-flex justify-content-center me-3">
                        <CtaWhatsapp />
                      </IconWrapper>
                      <a target="__blank" href={fields.ctawhatsapp?.url || '/'}>
                        {fields.ctawhatsapp?.title}
                      </a>
                    </div>
                  </div>
                </div>
              </CTA>
              <div className="d-flex pt-sm-0 pt-3 justify-content-end">
                <Button
                  arrow
                  className="filled secondary hover-secondary"
                  to={fields.ctalink?.url || '/'}
                >
                  {fields.ctalink?.title}
                </Button>
              </div>
            </Block>
          </div>
        </div>
      </div>
      <ApplyContainer id="apply">
        <div className="container">
          <div className="pb-lg-5 pb-3">
            <ParseContent content={fields.applytitle || ''} />
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <StyledFormDuuf
                defaultFields={{ input_9: vacancy.title }}
                id={layout.locale === 'nl_NL' ? 15 : 8}
                generate={false}
                privacyUrl={
                  layout.locale === 'nl_NL'
                    ? '/nl/privacyverklaring'
                    : '/privacy'
                }
                privacyUrlPlacement={
                  layout.locale === 'nl_NL'
                    ? 'privacy voorwaarden'
                    : 'privacy policy.'
                }
              >
                <div className="row justify-content-center">
                  <div className="col-lg-11">
                    <div className="row">
                      <div className="col-lg-7">
                        <FormDuufGroup hideIds={[6, 7, 8, 9, 11]} />
                      </div>
                      <div className="col-lg-5">
                        <FormDuufGroup showIds={[6, 7, 8, 11]} />
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <FormDuufSubmit />
                    </div>
                  </div>
                </div>
              </StyledFormDuuf>
            </div>
          </div>
        </div>
      </ApplyContainer>
    </StyledVacancy>
  )
}

export default Vacancy

const StyledFormDuuf = styled(FormDuuf)`
  & .form-duuf-group-consent {
    color: white;
    & a {
      color: ${({ theme }) => theme.color.secondary} !important;
      text-decoration: underline;
    }

    & input {
      margin-right: 10px;
    }
  }

  position: relative;

  & .form-duuf-group {
    margin-bottom: 2rem;
    max-width: 400px;

    @media screen and (max-width: 991px) {
      max-width: unset;
      margin-bottom: 1rem;
    }
  }

  & .form-duuf-label {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};
    color: ${({ theme }) => theme.color.text.light};
    font-size: 16px;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: ${({ theme }) => theme.color.greyDark};
    border-style: solid;
    border-width: 1px;
    min-height: 39px;
    padding: 0 0 0 10px;
    font-size: 16px;
    background-color: ${({ theme }) => theme.color.light};
    color: ${({ theme }) => theme.color.text.dark};

    &::placeholder {
      color: ${({ theme }) => theme.color.greyDark};
      font-family: ${({ theme }) => theme.font.family.secondary};
      font-size: 14px;
    }
  }

  & .form-duuf-textarea {
    padding: 5px 10px;
    min-height: 135px;
  }

  & .form-duuf-file-button {
    width: 100%;

    & button {
      position: relative;
      background-color: ${({ theme }) => theme.color.light};
      height: 100%;
      min-height: 39px;
      width: 100%;

      &::before {
        content: 'Selecteer het bestand';
        position: absolute;
        font-family: ${({ theme }) => theme.font.family.secondary};
        top: 9px;
        left: 12px;
        font-size: 14px;
        color: ${({ theme }) => theme.color.greyDark};
      }

      &::after {
        content: '';
        background-image: url(${upload});
        background-size: contain;
        background-repeat: no-repeat;
        height: 27px;
        width: 27px;
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
    color: ${({ theme }) => theme.color.text.light};
    min-width: 40%;
    font-family: ${({ theme }) => theme.font.family.secondary};
  }

  & .form-duuf-checkbox {
    display: flex;
    align-self: flex-start;
    margin-top: 5px;
  }

  & .form-duuf-checkbox-label {
    margin-left: 10px;
    margin-bottom: 0;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.light};
    color: ${({ theme }) => theme.color.text.light};
    font-size: 16px;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-group-submit {
    display: flex;
    justify-content: flex-end;
  }

  & .form-duuf-submit {
    position: relative;

    a,
    button {
      font-family: ${({ theme }) => theme.font.family.secondary};
      line-height: 1;
      margin: 0;
      font-size: 14px;
      border: 1px solid ${({ theme }) => theme.color.secondary};
      padding: 12px 50px 12px 20px;
      transition: all 0.3s ease;
      background-color: ${({ theme }) => theme.color.secondary};
      font-weight: ${({ theme }) => theme.font.weight.bold};
      color: ${({ theme }) => theme.color.primaryLight};
    }

    svg {
      color: ${({ theme }) => theme.color.dark};
      transition: all 0.3s ease;
    }

    &:hover {
      &::before {
        background-image: url(${altarrow});
      }

      & a,
      button {
        color: ${({ theme }) => theme.color.secondary};
        background-color: ${({ theme }) => theme.color.primaryLight};
        border-color: ${({ theme }) => theme.color.primaryLight};
      }
    }

    &::before {
      content: '';
      background-image: url(${arrow});
      background-size: cover;
      background-repeat: no-repeat;
      height: 16px;
      width: 19px;
      position: absolute;
      right: 20px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: ${({ theme }) => theme.color.text.light};
    font-size: 24px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-align: center;

    & b,
    strong {
      color: ${({ theme }) => theme.color.text.secondary};
    }
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`
